import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import useNotifications from "@notifications"

export default function useRequestsList() {

	// Use toast
	const { toastNotification } = useNotifications()

	const refRequestListTable = ref(null)

	const styleColumns = {
		thClass: 'text-center',
		tdClass: 'text-center'
	}

	// Table Handlers
	let tableColumns = [
		{ key: 'code', sortable: true, label: 'CÓDIGO', ...styleColumns },
		{ key: 'department_name', sortable: true, label: 'DEPARTAMENTO', ...styleColumns },
		{ key: 'title', sortable: true, label: 'SOLICITUD', thClass: styleColumns.thClass },
		{ key: 'state_name', sortable: true, label: 'ESTADO', ...styleColumns },
		{ key: 'bidders_count', sortable: true, label: 'N° POSTORES <br/> PARTICIPANTES', ...styleColumns },
		{ key: 'submitted_at', sortable: true, label: 'FECHA <br/> REGISTRO', ...styleColumns },
		{ key: 'actions', sortable: false, label: 'OPCIONES', ...styleColumns }
	];

	const perPage = ref(10);
	const totalRequests = ref(0);
	const currentPage = ref(1);
	const perPageOptions = [10, 25, 50, 100];
	const searchQuery = ref('');
	const sortBy = ref('createdAt');
	const isSortDirDesc = ref(true);

	const requestStatesFilter = ref(null);

	const dataMeta = computed(() => {
		const localItemsCount = refRequestListTable.value ? refRequestListTable.value.localItems.length : 0;
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalRequests.value,
		}
	});

	const refetchData = () => {
		refRequestListTable.value.refresh();
	};

	watch([currentPage, perPage, searchQuery, requestStatesFilter], () => {
		refetchData();
	});

	const fetchRequests = (ctx, callback) => {

		store
			.dispatch('app-request/fetchRequests', {
				q: searchQuery.value,
				perPage: perPage.value,
				page: currentPage.value,
				sortBy: sortBy.value,
				sortDesc: isSortDirDesc.value,
				requestStates: requestStatesFilter.value
			})
			.then(response => {
				const { requests, total } = response.data;
				callback(requests);
				totalRequests.value = total;
			})
			.catch(() => {
				toastNotification('Error al obtener la lista de solicitudes', false)
			})
	};

	return {
		fetchRequests,
		tableColumns,
		perPage,
		currentPage,
		totalRequests,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refRequestListTable,

		refetchData,

		// Extra Filters
		requestStatesFilter,
	}
}