<template>
	<b-card no-body>
		<b-card-header>
			<h5 class="mb-0 text-white">
				<b>FILTROS</b>
			</h5>
		</b-card-header>
		<b-card-body class="pt-2">
			<b-row>
				<b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label for="requestStates">
						<strong>ESTADO</strong>
					</label>
					<v-select
						id="requestStates"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						multiple
						v-model="formData.requestStatesArray"
						:options="options.requestStates"
						class="w-100"
						@input="inputRequestStatesVal()"
					/>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>

<script>
import { ref } from "@vue/composition-api"
import vSelect from "vue-select"

export default {
	components: {
		vSelect
	},
	props: {
		options: {
			type: Object,
			required: true
		}
	},
	setup(props, { emit }) {
		// REFS
		const formData = ref({
			requestStatesArray: []
		})

		const inputRequestStatesVal = () => {
			if (formData.value.requestStatesArray) {
				const array = formData.value.requestStatesArray.map(
					(item) => item.value
				)
				emit("update:requestStatesFilter", array.join(","))

				return array
			}

			return null
		}

		return {
			// DATA
			formData,

			// METHODS
			inputRequestStatesVal
		}
	}
}
</script>

<style lang="scss" scoped>
.card-header {
	background-color: var(--primary);
	padding: 1rem 1.5rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>