import axios from '@axios';

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchRequests(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				axios
					.get('/requests', { params: queryParams })
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		fetchRequest(ctx, { id }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/requests/${id}/edit`)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		createRequest(ctx, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post('/requests', payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		updateRequest(ctx, { id, payload }) {
			return new Promise((resolve, reject) => {
				axios
					.put(`/requests/${id}`, payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		deleteRequest(ctx, { id }) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`/requests/${id}`)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		}
	}
}